/** @format */

import {
  Card,
  Box,
  Table,
  Center,
  Loader,
  Button,
  Grid,
  Text,
  useMantineTheme,
  TextInput,
  Input,
  Flex,
  Textarea,
  Pagination,
  Image,
} from "@mantine/core";
import jsPDF from "jspdf";
import { Add, Delete, MovieCreation, RemoveRedEye } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";

import { useNavigate } from "react-router-dom";
import { RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import CustomModal from "../../component/customModal";
import { Dropzone } from "@mantine/dropzone";
import { Formik } from "formik";
import projectValidationSchema from "../../constant/validation";
import {
  createProjectReq,
  deleteProjectReq,
  getListReq,
  searchFromList,
} from "./service/service";
import { useDebounce } from "../../hooks";
import { notifications } from "@mantine/notifications";
let interval;
const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [opened, setOpened] = useState(false);
  const [isSubmitted, setSubmitted] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [report, setReport] = useState<any>({});
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const option = [
    { label: "Select Project Type", value: "", disable: true },
    { label: "YouTube", value: "youtube", disable: false },
    { label: "Media File", value: "link", disable: false },
  ];
  const [initialValues, setInitialValues] = useState({
    projectName: "",
    type: "",
    projectSrc: "",
    description: "",
  });

  const theme = useMantineTheme();
  useEffect(() => {
    getListReq()
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        setLoading(false);
        setProjectList(res);
      })
      .catch((err: any) => {
        if (err?.response.status == "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          console.log(err);

          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });

          setProjectList([]);
        }
      });
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await deleteProjectReq(id);
      setProjectList(res);
    } catch (error) {
      window.alert(error);
    }
  };

  const handleGeneratePdf = async (id) => {
    try {
      navigate(`/report/${id}`);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const rows = projectList
    .filter((project: { projectName: string }) => {
      if (project.projectName === "") {
        return project;
      } else if (project.projectName.toLocaleLowerCase().includes(search)) {
        return project;
      }
    })
    .slice(startIndex, endIndex)
    .map((element: any, index: number) => (
      <tr key={index}>
        <td width={250} align="center">
          <Text>{element.projectName}</Text>
        </td>

        <td width={500}>
          <Text
            my={"xs"}
            size={"sm"}
            color={element.status === "success" ? "green" : "red"}
          >
            {element.projectDescription}
          </Text>
        </td>
        <td align="center">
          <Text>{element.type}</Text>
        </td>
        <td align="center">
          <Text
            style={{
              color: `${
                element.status === "Completed"
                  ? "green"
                  : element.status === "Failed"
                  ? "red"
                  : "rgb(0, 30, 107)"
              }`,
            }}
          >
            {element.status}
            {/* {element.status === 'Completed' && !element?.isSaveEmbedding
              ? 'Processing'
              : element.status === 'Completed' && !element?.isSaveEmbedding} */}
          </Text>
        </td>
        <td align="center">
          <Text
            style={{
              color: `${
                element.status === "Completed" && element?.isSaveEmbeddings
                  ? "green"
                  : element.status === "Failed" && !element?.isSaveEmbeddings
                  ? "red"
                  : "rgb(0, 30, 107)"
              }`,
            }}
          >
            {(element.status === "Completed" ||
              element.status === "Initialising") &&
            !element?.isSaveEmbeddings
              ? "Processing"
              : element.status === "Failed" && !element?.isSaveEmbeddings
              ? "Failed"
              : element.status === "Completed" && element?.isSaveEmbeddings
              ? "Available"
              : "Initialising"}
          </Text>
        </td>
        <td style={{ display: "flex", justifyContent: "center" }}>
          <Button
            title="Redirect to Analysis tab"
            styles={{ root: { backgroundColor: "white !important" } }}
            variant={theme.colorScheme === "dark" ? "subtle" : "white"}
            color={"dark"}
            disabled={element.status !== "Completed" ? true : false}
            onClick={() => {
              if (element.status === "Completed") {
                clearInterval(interval);
                navigate(`/projectDetail?id=${element._id}`);
              }
            }}
          >
            <RemoveRedEye />
          </Button>
          <Button
            styles={{ root: { backgroundColor: "white !important" } }}
            title="Generate PDF Report"
            variant={theme.colorScheme === "dark" ? "subtle" : "white"}
            color={"dark"}
            disabled={element.status !== "Completed" ? true : false}
            onClick={() => {
              if (element.status === "Completed") {
                handleGeneratePdf(element._id);
              }
            }}
          >
            <Image
              src={
                "https://w7.pngwing.com/pngs/925/474/png-transparent-computer-icons-report-computer-software-report-miscellaneous-text-rectangle-thumbnail.png"
              }
              width={20}
            ></Image>
          </Button>

          <Button
            title="Remove from Dashboard"
            variant={theme.colorScheme === "dark" ? "subtle" : "white"}
            p={5}
            color="dark"
          >
            <Delete
              style={{ color: "red" }}
              onClick={() => {
                handleDelete(element._id);
              }}
            />
          </Button>
        </td>
      </tr>
    ));
  const handleSubmit = async (value) => {
    setSubmitted(false);
    const formValue = {
      projectDescription: value.description,
      projectName: value.projectName,
      projectLabels: "",
      type: value?.type ? value?.type : "link",
      link: value.type === "youtube" ? value.projectSrc : "",
      video_file: value.projectSrc,
    };

    const data = new FormData();

    Object.entries(formValue).map(([keyName, value]: any) => {
      data.append(keyName, value);
    });
    createProjectReq(data)
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }

        getListReq()
          .then((res: any) => {
            setProjectList(res);
            setOpened(false);
            setSubmitted(true);
            setLoading(false);
          })
          .catch((err) => {
            if (err?.response.status == "401") {
              localStorage.removeItem("access_token");
              navigate("/login");
            } else {
              notifications.show({
                message: err?.message,
                color: "red",
                autoClose: 2000,
                icon: <ErrorIcon />,
              });
              setProjectList([]);
            }
          });
      })
      .catch((err) => {
        console.log("ERROR: ", err?.message);
      });
  };

  const handleRefresh = () => {
    getListReq()
      .then((res: any) => {
        if (res?.isError) {
          throw res;
        }
        setLoading(false);
        setProjectList(res);
      })
      .catch((err) => {
        if (err?.response.status == "401") {
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          notifications.show({
            message: err?.message,
            color: "red",
            autoClose: 2000,
            icon: <ErrorIcon />,
          });
          setProjectList([...projectList]);
        }
      });
  };

  const handleModel = () => {
    setOpened(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const fetchProjects = async (filterText: SetStateAction<string>) => {
    try {
      const res = await searchFromList(filterText);
      setProjectList(res);
    } catch (error) {
      window.alert(error);
    }

    setSearch(filterText);
  };
  const debouncedFetchProjects = useDebounce(fetchProjects, 500);
  useEffect(() => {
    interval = setInterval(() => handleRefresh(), 6000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <CustomModal isOpen={opened} onClose={() => setOpened(false)}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={projectValidationSchema}
        >
          {({
            values,
            touched,
            handleBlur,
            handleChange,
            errors,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <fieldset style={{ border: "none" }}>
                  <Input.Wrapper
                    label="Project name"
                    m={10}
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <TextInput
                      type="text"
                      name="projectName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.projectName && errors.projectName
                          ? `${errors.projectName}`
                          : null
                      }
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    label="Description"
                    m={10}
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <Textarea
                      placeholder="Add Description..."
                      name="description"
                      minRows={2}
                      maxRows={4}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.description && errors.description
                          ? `${errors.description}`
                          : null
                      }
                    />
                  </Input.Wrapper>
                  <Input.Wrapper
                    m={10}
                    label="Project Type"
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <Input
                      component="select"
                      placeholder="Select project type"
                      name="type"
                      value={values.type}
                      error={
                        touched.type && errors.type ? `${errors.type}` : null
                      }
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("projectSrc", "");
                      }}
                      onBlur={handleBlur}
                      // rightSection={<KeyboardArrowDownOutlined />}
                    >
                      {option.map((val, ind) => {
                        return (
                          <option
                            disabled={val.disable}
                            style={{ padding: "25px", height: "50px" }}
                            key={ind}
                            value={val.value}
                          >
                            {val.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Input.Wrapper>
                  {values.type === "link" && (
                  <Input.Wrapper
                    m={10}
                    mt={20}
                    styles={{ label: { fontWeight: "bold" } }}
                  >
                    <Dropzone
                      accept={[
                        "video/x-msvideo",
                        "video/mp4",
                        "video/mpeg",
                        "video/ogg",
                        "video/mp2t",
                        "video/webm",
                        "video/3gpp",
                        "video/3gpp2",
                      ]}
                      onDrop={(e) => {
                        setFieldValue("projectSrc", e[0]);
                      }}
                      onBlur={handleBlur}
                      name="projectSrc"
                    >
                      {values.projectSrc === "" && (
                        <Text align="center">Drop Video here</Text>
                      )}
                      {values.projectSrc !== "" && (
                        <Flex>
                          <div>
                            <MovieCreation />
                          </div>
                          <Text align="center">{values.projectSrc.name}</Text>
                        </Flex>
                      )}
                    </Dropzone>
                  </Input.Wrapper>
                 )} 
                  {values.type === "youtube" && (
                    <Input.Wrapper
                      m={10}
                      label="YouTube Link"
                      styles={{ label: { fontWeight: "bold" } }}
                    >
                      <TextInput
                        value={values.projectSrc}
                        name="projectSrc"
                        placeholder="paste Your YouTube link here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.projectSrc && errors.projectSrc
                            ? `${errors.projectSrc}`
                            : null
                        }
                        // rightSection={<LinkRounded />}
                      />
                    </Input.Wrapper>
                  )}
                  <Input.Wrapper m={10} mt={30}>
                    <Button
                      type="submit"
                      variant="filled"
                      leftIcon={
                        !isSubmitted ? (
                          <Loader size={20} color="#ffffff" />
                        ) : null
                      }
                    >
                      Upload
                    </Button>
                  </Input.Wrapper>
                </fieldset>
              </form>
            );
          }}
        </Formik>
      </CustomModal>
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
      >
        <Grid my={"2px"} gutter={"xs"}>
          <Grid.Col sm={6} md={4} lg={4}>
            <TextInput
              autoFocus
              placeholder="Search"
              icon={<i className="bx bx-search-alt" />}
              onChange={(e) => debouncedFetchProjects(e.target.value)}
            />
          </Grid.Col>
          <Grid.Col sm={0} md={4} lg={6}></Grid.Col>
          <Grid.Col sm={6} md={4} lg={2}>
            <Flex justify={"right"} align={"center"}>
              {/* <ActionIcon variant="gradient" mx={10} onClick={handleRefresh}>
                <Refresh />
              </ActionIcon> */}

              <Button
                variant="gradient"
                style={{ float: "right" }}
                leftIcon={<Add />}
                onClick={handleModel}
              >
                Create Project
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>
        <Box>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Project Name</th>
                <th>Description</th>
                <th style={{ textAlign: "center" }}>Type</th>
                <th style={{ textAlign: "center" }}>Status</th>{" "}
                <th style={{ textAlign: "center" }}>Chat</th>{" "}
                <th style={{ display: "flex", justifyContent: "center" }}>
                  Actions
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <Center style={{ minHeight: "calc(100vh - 386px)" }}>
                      <Loader variant="bars" />
                    </Center>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{rows}</tbody>
            )}
          </Table>
          <br />
          <br />
          <Pagination
            value={currentPage}
            onChange={handlePageChange}
            total={Math.ceil(projectList.length / itemsPerPage)}
          />
        </Box>
      </Card>
    </>
  );
};

export default Dashboard;
